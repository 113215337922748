@import 'styles/colors';

.tab-list-item {
  font-weight: 600;
  color: $kf-darkblue;
  display: inline-block;
  list-style: none;
  margin: 0 10px 0 0;
  padding: 0.5rem 0.75rem;
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.26), 0 2px 2px 0 rgba(0, 0, 0, 0.16),
    0 3px 1px -2px rgba(0, 0, 0, 0.2);

  &:hover {
    background-color: $kf-blue-hover;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.14), 0 4px 5px 0 rgba(0, 0, 0, 0.2),
      0 2px 4px -1px rgba(0, 0, 0, 0.2);
  }

  div {
    float: left;
    width: 10px;
    height: 10px;
    margin: 5px 5px 0 0;
    background-color: $white;
    border: 2px solid $kf-darkblue;
    border-radius: 7px;
  }
}

.tab-list-active {
  background-color: $kf-blue-hover;
  box-shadow: unset;
  div {
    border: 3px solid $kf-blue;
    border-radius: 8px;
  }

  &:hover {
    box-shadow: unset;
  }
}

.tab-disabled {
  cursor: not-allowed;
  background-color: $border-grey;
  box-shadow: unset;
  &:hover {
    background-color: $border-grey;
    box-shadow: unset;
  }
  div {
    border-color: $dark-gray;
  }
}
