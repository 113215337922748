@import 'styles/colors';

.check-box-container {
  position: relative;
  padding-left: 25px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.check-box-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  border: 2px solid $kf-darkblue;
  border-radius: 3px;
  background-color: $light-gray;
}

.check-box-container:hover input ~ .checkmark {
  height: 18px;
  width: 18px;
  background-color: $kf-blue-hover;
}

.check-box-container input:checked ~ .checkmark {
  height: 18px;
  width: 18px;
  background-color: $light-blue;
}

.multi-check-box-container input:checked ~ .checkmark {
  height: 20px;
  width: 20px;
  background-color: unset;
  border: unset;
  background-image: url(./checks.svg);
  background-repeat: no-repeat;
}

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.check-box-container input:checked ~ .checkmark:after {
  display: block;
}

.check-box-container .checkmark:after {
  left: 5px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid $white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.multi-check-box-container .checkmark:after {
  border: unset;
}

.check-box-disabled {
  cursor: not-allowed;
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    border: 1px solid $light-gray;
    border-radius: 3px;
    background-color: $light-gray;
  }
}
