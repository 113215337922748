@import 'styles/colors';

header {
  grid-area: header;
}

aside {
  grid-area: sidebar;
}

section {
  grid-area: section;
}

.container {
  display: grid;
  grid-template-columns: 400px auto auto;
  grid-template-rows: auto;
  grid-template-areas:
    'header header header'
    'sidebar section section';
}

.Toastify__toast--dark {
  background: #121212;
  color: #fff;
}
.Toastify__toast--default {
  background: #fff;
  color: #aaa;
}
.Toastify__toast--info {
  background: #2196f3;
}
.Toastify__toast--success {
  background: #04aa6d;
}
.Toastify__toast--warning {
  background: #ff9800;
}
.Toastify__toast--error {
  background: #f44336;
}

.Toastify__progress-bar--default {
  background: #aaa;
}
.Toastify__progress-bar--dark {
  background: #bb86fc;
}

.Toastify__close-button {
  color: #fff;
  background: transparent;
  outline: none;
  border: none;
  padding: 0;
  cursor: pointer;
  opacity: 0.7;
  transition: 0.3s ease;
  -ms-flex-item-align: start;
  align-self: flex-start;
}

.Toastify__close-button--default {
  color: #000;
  opacity: 0.3;
}

.Toastify__close-button > svg {
  fill: currentColor;
  height: 18px;
  width: 16px;
}
.Toastify__close-button:hover,
.Toastify__close-button:focus {
  opacity: 1;
}
