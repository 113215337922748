@import 'styles/colors';

.btn {
  font-size: 17px;
  line-height: 19px;
  cursor: pointer;
  font-family: GimbalGrot-Bol, Helvetica, Arial, sans-serif;
  font-weight: 400 !important;
  font-style: normal !important;
  margin: 5px;

  &:focus {
    outline: 0;
  }
}

.btn-default {
  color: $kf-darkblue;
  background-color: $bk-gold;
  border: none;
  border-radius: 4px;
  padding: 6px 20px 5px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.26), 0 2px 2px 0 rgba(0, 0, 0, 0.16),
    0 3px 1px -2px rgba(0, 0, 0, 0.2);

  &:hover,
  &:focus,
  &:active {
    background-color: $bk-gold-hover;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.14), 0 4px 5px 0 rgba(0, 0, 0, 0.2),
      0 2px 4px -1px rgba(0, 0, 0, 0.2);
  }
}

.btn-primary {
  color: $kf-darkblue;
  background-color: $white;
  border-radius: 4px;
  padding: 6px 20px 5px;
  margin-bottom: 0;
  border: 0;
  font-size: 14px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.26), 0 2px 2px 0 rgba(0, 0, 0, 0.16),
    0 3px 1px -2px rgba(0, 0, 0, 0.2);

  &:hover,
  &:focus,
  &:active {
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.14), 0 4px 5px 0 rgba(0, 0, 0, 0.2),
      0 2px 4px -1px rgba(0, 0, 0, 0.2);
  }
}

.btn-disabled {
  background-color: $border-grey;
  box-shadow: unset;
  cursor: not-allowed;

  &:hover,
  &:focus,
  &:active {
    background-color: $border-grey;
  }
}

.btn-denger {
  @extend .btn-primary;
  color: $white;
  background-color: $error-red;

  &:hover {
    background-color: $red-hover;
  }

  &:focus,
  &:active {
    color: $white;
    background-color: $red-hover;
    border-color: $error-red;
  }
}

.btn-success {
  @extend .btn-primary;
  color: $white;
  background-color: $green;

  &:hover {
    background-color: $green-hover;
  }

  &:focus,
  &:active {
    color: $white;
    background-color: $green-hover;
    border-color: $green;
  }
}

.btn-link {
  border: 0;
  text-decoration: underline;
  font-family: inherit;
  font-size: inherit;
  background-color: transparent;
}
