@import 'styles/colors';

.text-input {
  border: 1px solid $border-grey;
  border-radius: 3px;
  padding: 8px;
  background-color: $white;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.26), 0 2px 2px 0 rgba(0, 0, 0, 0.16),
    0 3px 1px -2px rgba(0, 0, 0, 0.2);

  &:focus {
    outline: 0;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.14), 0 4px 5px 0 rgba(0, 0, 0, 0.2),
      0 2px 4px -1px rgba(0, 0, 0, 0.2);
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.label {
  display: flex;
  align-items: center;
  margin-right: 5px;
  margin-bottom: 5px;
}

.text-input-disabled {
  background-color: $border-grey;
  box-shadow: unset;
  cursor: not-allowed;
}

.react-inputs-validation__msg___pxv8o.react-inputs-validation__error___2aXSp {
  color: $error-red;
  font-size: 13px;
}

.react-inputs-validation__error___2aXSp input {
  border: 1px solid $error-red;
  color: $error-red;
}
