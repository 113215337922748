$kf-darkblue: #050037;
$kf-blue: #64a5ff;
$kf-blue-hover: #cce1ff;
$bk-gold: #f5cea3;
$bk-gold-hover: #fef3e7;
$bk-gold-bg: #fffbf9;
$gray: #555555;
$light-gray: #f4f4f4;
$border-grey: #e2e1e1;
$dark-gray: #646464;
$error-red: #c40000;
$red-hover: #c82333;
$black: #000;
$white: #fff;
$light-blue: #0069d9;
$border-blue: #0062cc;
$green: #28a745;
$green-hover: #218838;
