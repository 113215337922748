@import 'styles/colors';

.drop-down {
  width: fit-content;
  font-family: inherit;
  font-size: 14px;
  padding: 7px;
  padding-right: 40px;
  border: 1px solid $border-grey;
  border-width: 0 0 1px 0;
  border-radius: 5px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.26), 0 2px 2px 0 rgba(0, 0, 0, 0.16),
    0 3px 1px -2px rgba(0, 0, 0, 0.2);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url(./arrow-down.svg) !important;
  background-repeat: no-repeat;
  background-position: right 8px center;

  &:focus {
    outline: 0;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.14), 0 4px 5px 0 rgba(0, 0, 0, 0.2),
      0 2px 4px -1px rgba(0, 0, 0, 0.2);
  }
}

.drop-down-label {
  margin-right: 10px;
}

.multiselect-container {
  .search-wrapper,
  .searchWrapper {
    padding: 7px;
    border: 1px solid $border-grey;
    border-width: 0 0 1px 0;
    border-radius: 5px;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.14), 0 4px 5px 0 rgba(0, 0, 0, 0.2),
      0 2px 4px -1px rgba(0, 0, 0, 0.2);

    .searchBox {
      font-family: inherit;
      font-size: 14px;
      padding: 7px;
      padding-right: 40px;
      border: 1px solid $border-grey;
      border-width: 1px;
      border-radius: 5px;
    }
  }

  .chip {
    background: $light-blue;
    margin-top: 5px;
  }

  .highlightOption {
    background: $light-blue;
  }

  .optionContainer {
    .notFound {
      display: none;
    }
  }
}

.multiselect-container-disabled {
  outline: 0;
  box-shadow: unset;
}
