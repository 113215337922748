@import 'styles/colors';

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  outline: 0;
  z-index: 1051;
  overflow-x: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.modal::-webkit-scrollbar {
  display: none;
}

.modal-wrapper {
  background: $bk-gold-bg;
  border: 1px solid #d0cccc;
  margin: 1.75rem auto;
  width: auto;
  height: fit-content;
  max-width: 750px;
  border-radius: 10px;
  z-index: 1052;
}

.modal-header {
  background: $bk-gold-bg;
  height: 40px;
  line-height: 40px;
  padding: 5px 20px;
  text-align: right;
  border-radius: 10px 10px 0 0;

  h4 {
    float: left;
    margin: 0;
    padding: 0;
  }
}

.modal-body {
  padding: 10px 15px;
  text-align: left;
  height: fit-content;
  width: auto;
}

.modal-footer {
  background: $bk-gold-bg;
  height: 35px;
  padding: 15px;
  border-radius: 0 0 10px 10px;

  button {
    margin-right: 10px;
  }
}

.close-modal-btn {
  color: $dark-gray;
  cursor: pointer;
  float: right;
  padding: 0 10px;
  font-size: 35px;
  margin: 5px;
  background: $white;
  border-radius: 5px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.26), 0 2px 2px 0 rgba(0, 0, 0, 0.16),
    0 3px 1px -2px rgba(0, 0, 0, 0.2);
}

.close-modal-btn:hover {
  color: black;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.14), 0 4px 5px 0 rgba(0, 0, 0, 0.2),
    0 2px 4px -1px rgba(0, 0, 0, 0.2);
}
