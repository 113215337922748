@import 'styles/colors';

.tab-list-container {
  display: flex;
  padding-bottom: 10px;
  border-bottom: 4px solid $border-grey;

  .tab-list {
    width: 100%;
    margin-block-end: 0;
    padding-left: 0;
    padding: 0 0 5px 15px;
  }

  .tab-list-children {
    width: fit-content;
    display: flex;
    align-self: center;
    float: right;
  }

  .hidden-tab-content {
    display: hidden;
  }
}
