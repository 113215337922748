@import './colors';

@font-face {
  font-family: GimbalGrot-Bol;
  src: url(fonts/39B2EE_0_0.eot);
  src: url(fonts/39B2EE_0_0.eot?#iefix) format('embedded-opentype'),
    url(fonts/39B2EE_0_0.woff2) format('woff2'),
    url(fonts/39B2EE_0_0.woff) format('woff'),
    url(fonts/39B2EE_0_0.ttf) format('truetype');
}
@font-face {
  font-family: GimbalGrot-Ital;
  src: url(fonts/39B2EE_1_0.eot);
  src: url(fonts/39B2EE_1_0.eot?#iefix) format('embedded-opentype'),
    url(fonts/39B2EE_1_0.woff2) format('woff2'),
    url(fonts/39B2EE_1_0.woff) format('woff'),
    url(fonts/39B2EE_1_0.ttf) format('truetype');
}
@font-face {
  font-family: GimbalGrot-Reg;
  src: url(fonts/39B2EE_2_0.eot);
  src: url(fonts/39B2EE_2_0.eot?#iefix) format('embedded-opentype'),
    url(fonts/39B2EE_2_0.woff2) format('woff2'),
    url(fonts/39B2EE_2_0.woff) format('woff'),
    url(fonts/39B2EE_2_0.ttf) format('truetype');
}

body {
  color: $kf-darkblue;
  background-color: $white;
  font-family: GimbalGrot-Reg, Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  *,
  :after,
  :before {
    box-sizing: revert;
  }
}

button,
input,
select {
  color: $kf-darkblue;
}

h1,
h2,
h3,
h4,
h5 {
  color: $kf-darkblue;
  font-family: GimbalGrot-Bol, Helvetica, Arial, sans-serif;
  font-weight: 400 !important;
}

h1 {
  font-size: 54px;
}

h2 {
  font-size: 41px;
}

h3 {
  font-size: 33px;
}

h4 {
  font-size: 22px;
}

h5 {
  font-size: 18px;
}

p {
  font-size: 17px;
  font-family: GimbalGrot-Reg, Helvetica, Arial, sans-serif;
  font-weight: 400 !important;
  font-style: normal !important;
}

p.large {
  font-size: 21px;
}

small {
  font-size: 14px;
}

.no-margin {
  margin: 0;
}
