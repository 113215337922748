@import 'styles/colors';

.page-not-found {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  h1 {
    color: $kf-darkblue;
  }
}
