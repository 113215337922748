@import 'styles/colors';

.rdw-editor-toolbar {
  font-size: 12px;
}

.rdw-option-wrapper {
  min-width: 15px;
  height: 12px;
}

.toolbarClassName {
  background-color: $light-gray;
  margin-bottom: 0;
  border: 1px solid $border-grey;
  border-bottom: unset;
  border-radius: 5px 5px 0 0;
}

.editorClassName {
  background-color: $white;
  padding: 5px;
  border: 1px solid $border-grey;
  border-radius: 0 0 5px 5px;
}

.editorReadOnly {
  background-color: $border-grey;
}

.rdw-editor-main {
  max-height: 250px;
}
