@import 'styles/colors';

.dateSelect {
  display: flex;
  margin: 5px 0;
  padding: 5px;
  background-color: $white;
  cursor: pointer;
  text-align: center;
  border-radius: 4px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.26), 0 2px 2px 0 rgba(0, 0, 0, 0.16),
    0 3px 1px -2px rgba(0, 0, 0, 0.2);
  span {
    padding: 0px 5px 0px 8px;
  }

  &:hover,
  &:focus,
  &:active {
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.14), 0 4px 5px 0 rgba(0, 0, 0, 0.2),
      0 2px 4px -1px rgba(0, 0, 0, 0.2);
  }
}

.dateSelect-disabled {
  background-color: $border-grey;
  box-shadow: unset;
  cursor: not-allowed;
}
